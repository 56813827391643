import fetch from "auth/FetchInterceptor";

const partnerService = {};

partnerService.Create = function (data) {
  return fetch({
    url: "/Partner/Create",
    method: "post",
    data,
  });
};

partnerService.Update = function (data) {
  return fetch({
    url: "/Partner/Update",
    method: "post",
    data,
  });
};
partnerService.Activate = function (params) {
  return fetch({
    url: "/Partner/Activate",
    method: "post",
    params,
  });
};
partnerService.GetAll = function () {
  return fetch({
    url: "/Partner/GetAll",
    method: "get",
  });
};
partnerService.GetById = function (params) {
  return fetch({
    url: "/Partner/GetById",
    method: "get",
    params,
  });
};

export default partnerService;
