import fetch from "auth/FetchInterceptor";

const ourProcessService = {};

ourProcessService.Create = function (data) {
  return fetch({
    url: "/OurProcess/Create",
    method: "post",
    data,
  });
};

ourProcessService.Update = function (data) {
  return fetch({
    url: "/OurProcess/Update",
    method: "post",
    data,
  });
};
ourProcessService.Activate = function (params) {
  return fetch({
    url: "/OurProcess/Activate",
    method: "post",
    params,
  });
};
ourProcessService.GetAll = function () {
  return fetch({
    url: "/OurProcess/GetAll",
    method: "get",
  });
};
ourProcessService.GetById = function (params) {
  return fetch({
    url: "/OurProcess/GetById",
    method: "get",
    params,
  });
};

export default ourProcessService;
