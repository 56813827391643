import fetch from "auth/FetchInterceptor";

const ourSolutionService = {};

ourSolutionService.Create = function (data) {
  return fetch({
    url: "/OurSolution/Create",
    method: "post",
    data,
  });
};

ourSolutionService.Update = function (data) {
  return fetch({
    url: "/OurSolution/Update",
    method: "post",
    data,
  });
};
ourSolutionService.Activate = function (params) {
  return fetch({
    url: "/OurSolution/Activate",
    method: "post",
    params,
  });
};
ourSolutionService.GetAll = function () {
  return fetch({
    url: "/OurSolution/GetAll",
    method: "get",
  });
};
ourSolutionService.GetById = function (params) {
  return fetch({
    url: "/OurSolution/GetById",
    method: "get",
    params,
  });
};

export default ourSolutionService;
