import fetch from "auth/FetchInterceptor";

const heroBannerService = {};

heroBannerService.UploadFiles = function (data) {
  return fetch({
    url: "/Carrousel/UploadFiles",
    method: "post",
    data,
  });
};

heroBannerService.GetAll = function () {
  return fetch({
    url: "/Carrousel/GetAll",
    method: "get",
  });
};
heroBannerService.Delete = function (params) {
  return fetch({
    url: "/Carrousel/Delete",
    method: "post",
    params,
  });
};
export default heroBannerService;
