import fetch from "auth/FetchInterceptor";

const MailConfigService = {};

MailConfigService.GetMailSettings = async function () {
  return fetch({
    url: "/MailConfig/GetMailSettings",
    method: "get",
  });
};

MailConfigService.UpdateSettings = async function (data) {
  return fetch({
    url: "/MailConfig/UpdateSettings",
    method: "post",
    data,
  });
};

export default MailConfigService;
