import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import aboutUsDetailsService from "services/AboutUsDetailsService";
import { message } from "antd";

export const initialState = {
  loadingAllAboutUsDetails: false,
  loadingActivateAboutUsDetails: false,
  allAboutUsDetails: [],
  createLoading: false,
  updateLoading: false,
  loadingAboutDetailsbyId: false,
  AboutDetailsbyId: {},
};

export const Create = createAsyncThunk(
  "/AboutUsDetail/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await aboutUsDetailsService.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/AboutUsDetail/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await aboutUsDetailsService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/AboutUsDetail/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await aboutUsDetailsService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/AboutUsDetail/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await aboutUsDetailsService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/AboutUsDetail/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await aboutUsDetailsService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const aboutUsDetailsSlice = createSlice({
  name: "aboutUsDetailsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingAllAboutUsDetails = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllAboutUsDetails = false;
        state.allAboutUsDetails = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllAboutUsDetails = false;
        state.allAboutUsDetails = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivateAboutUsDetails = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivateAboutUsDetails = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivateAboutUsDetails = false;
      });
  },
});

export const {} = aboutUsDetailsSlice.actions;

export default aboutUsDetailsSlice.reducer;
