import fetch from "auth/FetchInterceptor";

const rapidDeploymentService = {};

rapidDeploymentService.Create = function (data) {
  return fetch({
    url: "/RapidDeployment/Create",
    method: "post",
    data,
  });
};

rapidDeploymentService.Update = function (data) {
  return fetch({
    url: "/RapidDeployment/Update",
    method: "post",
    data,
  });
};
rapidDeploymentService.Activate = function (params) {
  return fetch({
    url: "/RapidDeployment/Activate",
    method: "post",
    params,
  });
};
rapidDeploymentService.GetAll = function () {
  return fetch({
    url: "/RapidDeployment/GetAll",
    method: "get",
  });
};
rapidDeploymentService.GetById = function (params) {
  return fetch({
    url: "/RapidDeployment/GetById",
    method: "get",
    params,
  });
};

export default rapidDeploymentService;
