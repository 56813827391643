import fetch from "auth/FetchInterceptor";

const NewsAndEventsService = {};

NewsAndEventsService.Create = function (data) {
  return fetch({
    url: "/NewsAndEvent/Create",
    method: "post",
    data,
  });
};
NewsAndEventsService.GetSSR = async function (data) {
  return fetch({
    url: "/NewsAndEvent/GetAllSSR",
    method: "post",
    data: data,
  });
};
NewsAndEventsService.Update = function (data) {
  return fetch({
    url: "/NewsAndEvent/Update",
    method: "post",
    data,
  });
};
NewsAndEventsService.Activate = function (params) {
  return fetch({
    url: "/NewsAndEvent/Activate",
    method: "post",
    params,
  });
};
NewsAndEventsService.GetAll = function () {
  return fetch({
    url: "/NewsAndEvent/GetAll",
    method: "get",
  });
};
NewsAndEventsService.GetById = function (params) {
  return fetch({
    url: "/NewsAndEvent/GetById",
    method: "get",
    params,
  });
};
NewsAndEventsService.GetNewsAndEventTypes = function () {
  return fetch({
    url: "/NewsAndEvent/GetTypes",
    method: "get",
  });
};

export default NewsAndEventsService;
