import fetch from "auth/FetchInterceptor";

const CertificationService = {};

CertificationService.Create = function (data) {
  return fetch({
    url: "/Certificate/Create",
    method: "post",
    data,
  });
};

CertificationService.Update = function (data) {
  return fetch({
    url: "/Certificate/Update",
    method: "post",
    data,
  });
};
CertificationService.Activate = function (params) {
  return fetch({
    url: "/Certificate/Activate",
    method: "post",
    params,
  });
};
CertificationService.GetAll = function () {
  return fetch({
    url: "/Certificate/GetAll",
    method: "get",
  });
};
CertificationService.GetById = function (params) {
  return fetch({
    url: "/Certificate/GetById",
    method: "get",
    params,
  });
};

export default CertificationService;
