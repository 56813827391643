import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import addressDetail from "services/AddressDetailService";
import { message } from "antd";

export const initialState = {
  loadingAllAddressDetail: false,
  loadingActivateAddressDetail: false,
  allAddressDetail: [],
  createLoading: false,
  updateLoading: false,
  loadingAddress: false,
  addresDetailById: {},
};

export const Create = createAsyncThunk(
  "/AddressDetail/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addressDetail.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/AddressDetail/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await addressDetail.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/AddressDetail/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await addressDetail.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/AddressDetail/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await addressDetail.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/AddressDetail/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addressDetail.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const addressDetailSlice = createSlice({
  name: "addressDetailSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingAllAddressDetail = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllAddressDetail = false;
        state.allAddressDetail = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllAddressDetail = false;
        state.allAddressDetail = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivateAddressDetail = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivateAddressDetail = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivateAddressDetail = false;
      });
  },
});

export const {} = addressDetailSlice.actions;

export default addressDetailSlice.reducer;
