import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";
const allRoles = ["Admin", "Seo Content Editor"];
export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "resetPassword",
    path: `${AUTH_PREFIX_PATH}/reset-password/:userId/:token`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/new-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "editProfile",
    path: `${APP_PREFIX_PATH}/Account/EditProfile`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/Account/EditProfile")),
  },
  // {
  //   key: "screenVideo",
  //   path: `${APP_PREFIX_PATH}/Home/Video`,
  //   component: React.lazy(() => import("views/app-views/Home/ScreenVideo")),
  // },
  {
    key: "banner",
    path: `${APP_PREFIX_PATH}/General/Banner`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/General/Banner/List")),
  },
  {
    key: "addBanner",
    path: `${APP_PREFIX_PATH}/General/Banner/Create`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/General/Banner/Add")),
  },
  {
    key: "editBanner",
    path: `${APP_PREFIX_PATH}/General/Banner/Edit/:id`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/General/Banner/Edit")),
  },
  // {
  //   key: "aboutCompany",
  //   path: `${APP_PREFIX_PATH}/Home/AboutCompany`,
  //   component: React.lazy(() => import("views/app-views/Home/AboutCompany")),
  // },
  // {
  //   key: "whoWeAre",
  //   path: `${APP_PREFIX_PATH}/Home/WhoWeAre`,
  //   component: React.lazy(() => import("views/app-views/Home/WhoWeAre")),
  // },

  // {
  //   key: "aboutUsDetails",
  //   path: `${APP_PREFIX_PATH}/AboutUs/Details/List`,
  //   component: React.lazy(() =>
  //     import("views/app-views/AboutUs/AboutUsDetails/List")
  //   ),
  // },
  // {
  //   key: "addAboutUsDetail",
  //   path: `${APP_PREFIX_PATH}/AboutUs/Details/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/AboutUs/AboutUsDetails/Add")
  //   ),
  // },
  // {
  //   key: "editAboutUsDetail",
  //   path: `${APP_PREFIX_PATH}/AboutUs/Details/Edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/AboutUs/AboutUsDetails/Edit")
  //   ),
  // },

  // {
  //   key: "addOnPack",
  //   path: `${APP_PREFIX_PATH}/RapidDeployemnt/AddOnPack`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/AddOnPack/List")
  //   ),
  // },
  // {
  //   key: "createAddOnPack",
  //   path: `${APP_PREFIX_PATH}/RapidDeployemnt/AddOnPack/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/AddOnPack/Add")
  //   ),
  // },
  // {
  //   key: "editAddOnPack",
  //   path: `${APP_PREFIX_PATH}/RapidDeployemnt/AddOnPack/Edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/AddOnPack/Edit")
  //   ),
  // },

  // {
  //   key: "addressDetail",
  //   path: `${APP_PREFIX_PATH}/ContactUs/AddressDetail`,
  //   component: React.lazy(() =>
  //     import("views/app-views/ContactUs/AddressDetail/List")
  //   ),
  // },
  // {
  //   key: "addAddressDetail",
  //   path: `${APP_PREFIX_PATH}/ContactUs/AddressDetail/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/ContactUs/AddressDetail/Add")
  //   ),
  // },
  // {
  //   key: "editAddressDetail",
  //   path: `${APP_PREFIX_PATH}/ContactUs/AddressDetail/Edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/ContactUs/AddressDetail/Edit")
  //   ),
  // },
  {
    key: "userList",
    path: `${APP_PREFIX_PATH}/User`,
    component: React.lazy(() => import("views/app-views/User/List")),
  },
  {
    key: "addUser",
    path: `${APP_PREFIX_PATH}/User/Create`,
    component: React.lazy(() => import("views/app-views/User/Add")),
  },
  {
    key: "editUser",
    path: `${APP_PREFIX_PATH}/User/Edit/:id`,
    component: React.lazy(() => import("views/app-views/User/Edit")),
  },
  {
    key: "blog",
    path: `${APP_PREFIX_PATH}/Blog/Blog`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/Blog/List")),
  },
  {
    key: "addBlog",
    path: `${APP_PREFIX_PATH}/Blog/Create`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/Blog/Add")),
  },
  {
    key: "editBlog",
    path: `${APP_PREFIX_PATH}/Blog/Edit/:id`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/Blog/Edit")),
  },
  {
    key: "certificate",
    path: `${APP_PREFIX_PATH}/General/Certificate`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/Certificate/List")),
  },
  {
    key: "addCertificate",
    path: `${APP_PREFIX_PATH}/General/Certificate/Create`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/Certificate/Add")),
  },
  {
    key: "editCertificate",
    path: `${APP_PREFIX_PATH}/General/Certificate/Edit/:id`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/Certificate/Edit")),
  },
  // {
  //   key: "faq",
  //   path: `${APP_PREFIX_PATH}/Home/FAQ`,
  //   component: React.lazy(() => import("views/app-views/Home/Faq/List")),
  // },
  // {
  //   key: "addFaq",
  //   path: `${APP_PREFIX_PATH}/Home/FAQ/Create`,
  //   component: React.lazy(() => import("views/app-views/Home/Faq/Add")),
  // },
  // {
  //   key: "editFaq",
  //   path: `${APP_PREFIX_PATH}/Home/FAQ/Edit/:id`,
  //   component: React.lazy(() => import("views/app-views/Home/Faq/Edit")),
  // },

  // {
  //   key: "howDoesItWork",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/HowDoesItWork`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/HowDoesItWork/List")
  //   ),
  // },
  // {
  //   key: "addHowDoesItWork",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/HowDoesItWork/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/HowDoesItWork/Add")
  //   ),
  // },
  // {
  //   key: "editHowDoesItWork",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/HowDoesItWork/Edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/HowDoesItWork/Edit")
  //   ),
  // },

  // {
  //   key: "ourProcess",
  //   path: `${APP_PREFIX_PATH}/AboutUs/OurProcess`,
  //   component: React.lazy(() =>
  //     import("views/app-views/AboutUs/OurProcess/List")
  //   ),
  // },
  // {
  //   key: "addOurProcess",
  //   path: `${APP_PREFIX_PATH}/AboutUs/OurProcess/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/AboutUs/OurProcess/Add")
  //   ),
  // },
  // {
  //   key: "editOurProcess",
  //   path: `${APP_PREFIX_PATH}/AboutUs/OurProcess/Edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/AboutUs/OurProcess/Edit")
  //   ),
  // },

  // {
  //   key: "ManageHowWeDoIt",
  //   path: `${APP_PREFIX_PATH}/AboutUs/HowWeDoIt/Manage`,
  //   component: React.lazy(() => import("views/app-views/AboutUs/HowWeDotIt")),
  // },
  {
    key: "partner",
    path: `${APP_PREFIX_PATH}/General/Partner`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/General/Partner/List")),
  },
  {
    key: "addPartner",
    path: `${APP_PREFIX_PATH}/General/Partner/Create`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/General/Partner/Add")),
  },
  {
    key: "editPartner",
    path: `${APP_PREFIX_PATH}/General/Partner/Edit/:id`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/General/Partner/Edit")),
  },

  // {
  //   key: "module",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/Module`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/Module/List")
  //   ),
  // },
  // {
  //   key: "addModule",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/Module/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/Module/Add")
  //   ),
  // },
  // {
  //   key: "editModule",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/Module/Edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/Module/Edit")
  //   ),
  // },

  // {
  //   key: "package",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/Package`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/Package/List")
  //   ),
  // },
  // {
  //   key: "addPackage",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/Package/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/Package/Add")
  //   ),
  // },
  // {
  //   key: "editPackage",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/Package/Edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/Package/Edit")
  //   ),
  // },

  // {
  //   key: "specialist",
  //   path: `${APP_PREFIX_PATH}/AboutUs/Specialist`,
  //   component: React.lazy(() =>
  //     import("views/app-views/AboutUs/Specialist/List")
  //   ),
  // },
  // {
  //   key: "addSpecialist",
  //   path: `${APP_PREFIX_PATH}/AboutUs/Specialist/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/AboutUs/Specialist/Add")
  //   ),
  // },
  // {
  //   key: "editSpecialist",
  //   path: `${APP_PREFIX_PATH}/AboutUs/Specialist/Edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/AboutUs/Specialist/Edit")
  //   ),
  // },

  {
    key: "newsAndEvents",
    path: `${APP_PREFIX_PATH}/News&Events`,
    role: allRoles,
    component: React.lazy(() => import("views/app-views/News&Events/List")),
  },
  {
    key: "addNewsAndEvents",
    path: `${APP_PREFIX_PATH}/News&Events/Create`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/News&Events/Add")),
  },
  {
    key: "editNewsAndEvents",
    path: `${APP_PREFIX_PATH}/News&Events/Edit/:id`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/News&Events/Edit")),
  },

  // {
  //   key: "ourSolution",
  //   path: `${APP_PREFIX_PATH}/Solutions/OurSolution`,
  //   component: React.lazy(() =>
  //     import("views/app-views/Solutions/OurSolution/List")
  //   ),
  // },
  // {
  //   key: "addOurSolution",
  //   path: `${APP_PREFIX_PATH}/Solutions/OurSolution/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/Solutions/OurSolution/Add")
  //   ),
  // },
  // {
  //   key: "editOurSolution",
  //   path: `${APP_PREFIX_PATH}/Solutions/OurSolution/Edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/Solutions/OurSolution/Edit")
  //   ),
  // },

  // {
  //   key: "service",
  //   path: `${APP_PREFIX_PATH}/Solutions/Service`,
  //   component: React.lazy(() =>
  //     import("views/app-views/Solutions/Service/List")
  //   ),
  // },
  // {
  //   key: "addService",
  //   path: `${APP_PREFIX_PATH}/Solutions/Service/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/Solutions/Service/Add")
  //   ),
  // },
  // {
  //   key: "editService",
  //   path: `${APP_PREFIX_PATH}/Solutions/Service/Edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/Solutions/Service/Edit")
  //   ),
  // },
  // {
  //   key: "rapidDeployment",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/RapidDeliveryDeployment`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/RapidDeliveryDeployment/List")
  //   ),
  // },
  // {
  //   key: "addRapidDeployment",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/RapidDeliveryDeployment/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/RapidDeliveryDeployment/Add")
  //   ),
  // },
  // {
  //   key: "editRapidDeployment",
  //   path: `${APP_PREFIX_PATH}/RapidDeployment/RapidDeliveryDeployment/Edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/RapidDeployment/RapidDeliveryDeployment/Edit")
  //   ),
  // },

  {
    key: "socialMedia",
    path: `${APP_PREFIX_PATH}/SocialMedia`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/Home/SocialMedia")),
  },
  {
    key: "subscriber",
    path: `${APP_PREFIX_PATH}/Subscriber/List`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/Subscriber/List")),
  },
  {
    key: "subscriberDetails",
    path: `${APP_PREFIX_PATH}/Subscriber/Details/:id`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/Subscriber/Details")),
  },
  {
    key: "reseller",
    path: `${APP_PREFIX_PATH}/Reseller/List`,
    component: React.lazy(() => import("views/app-views/Reseller/List")),
  },
  {
    key: "resellerDetails",
    path: `${APP_PREFIX_PATH}/Reseller/Details/:id`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/Reseller/Details")),
  },
  {
    key: "contactUs",
    path: `${APP_PREFIX_PATH}/ContactUs/List`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() =>
      import("views/app-views/ContactUs/ContactUs/List")
    ),
  },
  {
    key: "contactUsDetails",
    path: `${APP_PREFIX_PATH}/ContactUs/Details/:id`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() =>
      import("views/app-views/ContactUs/ContactUs/Details")
    ),
  },
  {
    key: "activity",
    path: `${APP_PREFIX_PATH}/Activity`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/Activity/List")),
  },
  {
    key: "addActivity",
    path: `${APP_PREFIX_PATH}/Activity/Create`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/Activity/Add")),
  },
  {
    key: "editActivity",
    path: `${APP_PREFIX_PATH}/Activity/Edit/:id`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/Activity/Edit")),
  },
  {
    key: "teamMember",
    path: `${APP_PREFIX_PATH}/General/Speakers`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/General/Team/List")),
  },
  {
    key: "addTeamMember",
    path: `${APP_PREFIX_PATH}/General/Speakers/Create`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/General/Team/Add")),
  },
  {
    key: "editTeamMember",
    path: `${APP_PREFIX_PATH}/General/Speakers/Edit/:id`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/General/Team/Edit")),
  },
  {
    key: "testimonial",
    path: `${APP_PREFIX_PATH}/General/Testimonial`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() =>
      import("views/app-views/General/Testimonial/List")
    ),
  },
  {
    key: "addTestimonial",
    path: `${APP_PREFIX_PATH}/General/Testimonial/Create`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() =>
      import("views/app-views/General/Testimonial/Add")
    ),
  },
  {
    key: "editTestimonial",
    path: `${APP_PREFIX_PATH}/General/Testimonial/Edit/:id`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() =>
      import("views/app-views/General/Testimonial/Edit")
    ),
  },
  {
    key: "mailConfig",
    path: `${APP_PREFIX_PATH}/MailConfiguration`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() => import("views/app-views/MailConfig")),
  },

  {
    key: "heroBanner",
    path: `${APP_PREFIX_PATH}/General/HeroBanner`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() =>
      import("views/app-views/General/Hero Banner/List")
    ),
  },
  {
    key: "addheroBanner",
    path: `${APP_PREFIX_PATH}/General/HeroBanner/Add`,
    roles: allRoles.filter((e) => e !== "Seo Content Editor"),
    component: React.lazy(() =>
      import("views/app-views/General/Hero Banner/Add")
    ),
  },
  // {
  //   key: "addHeroBanner",
  //   path: `${APP_PREFIX_PATH}/General/Testimonial/Create`,
  //   roles: allRoles.filter((e) => e !== "Seo Content Editor"),
  //   component: React.lazy(() =>
  //     import("views/app-views/General/Testimonial/Add")
  //   ),
  // },
  // {
  //   key: "editHeroBanner",
  //   path: `${APP_PREFIX_PATH}/General/Testimonial/Edit/:id`,
  //   roles: allRoles.filter((e) => e !== "Seo Content Editor"),
  //   component: React.lazy(() =>
  //     import("views/app-views/General/Testimonial/Edit")
  //   ),
  // },
];
