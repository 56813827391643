import fetch from "auth/FetchInterceptor";

const SubscriberService = {};

SubscriberService.GetAllSSR = async function (data) {
  return fetch({
    url: "/Subscriber/GetAllSSR",
    method: "post",
    data,
  });
};
SubscriberService.GetById = async function (params) {
  return fetch({
    url: "/Subscriber/GetById",
    method: "get",
    params,
  });
};

SubscriberService.Activate = function (params) {
  return fetch({
    url: `/Subscriber/Activate`,
    method: "post",
    params,
  });
};
SubscriberService.ExportToExcel = async function () {
  return fetch({
    url: "/Subscriber/ExportToExcel",
    method: "get",
    responseType: "blob",
  });
};

export default SubscriberService;
