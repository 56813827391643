import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SubscriberService from "services/SubscriberService";
import { message } from "antd";

export const initialState = {
  ssrData: [],
  loadingSSR: false,
  totalRecord: 0,
  loadingCreate: [],
  loadingUpdate: false,
  loadingActivate: false,
  loadingExcel: false,
};

export const GetAllSSR = createAsyncThunk(
  "/Subscriber/GetAllSSR",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SubscriberService.GetAllSSR(data);

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const GetById = createAsyncThunk(
  "/Subscriber/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await SubscriberService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/Subscriber/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SubscriberService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const ExportToExcel = createAsyncThunk(
  "/Subscriber/ExportToExcel",
  async (_, { rejectWithValue }) => {
    try {
      const response = await SubscriberService.ExportToExcel();

      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Subscriber.xlsx");
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const subscriberSlice = createSlice({
  name: "subscriber",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllSSR.pending, (state) => {
        state.loadingSSR = true;
      })
      .addCase(GetAllSSR.fulfilled, (state, action) => {
        state.loadingSSR = false;
        state.ssrData = action.payload.data;
        state.totalRecord = action.payload.totalRecord;
      })
      .addCase(GetAllSSR.rejected, (state) => {
        state.loadingSSR = false;
        state.ssrData = [];
      })

      .addCase(Activate.pending, (state) => {
        state.loadingActivate = true;
      })
      .addCase(Activate.fulfilled, (state) => {
        state.loadingActivate = false;
      })
      .addCase(Activate.rejected, (state) => {
        state.loadingActivate = false;
      })

      .addCase(ExportToExcel.pending, (state) => {
        state.loadingExcel = true;
      })
      .addCase(ExportToExcel.fulfilled, (state) => {
        state.loadingExcel = false;
      })
      .addCase(ExportToExcel.rejected, (state) => {
        state.loadingExcel = false;
      });
  },
});

export const {} = subscriberSlice.actions;

export default subscriberSlice.reducer;
