import fetch from "auth/FetchInterceptor";

const ContactUsService = {};

ContactUsService.GetAllSSR = async function (data) {
  return fetch({
    url: "/ContactUs/GetAllSSR",
    method: "post",
    data,
  });
};
ContactUsService.GetById = async function (params) {
  return fetch({
    url: "/ContactUs/GetById",
    method: "get",
    params,
  });
};
ContactUsService.ExportToExcel = async function () {
  return fetch({
    url: "/ContactUs/ExportToExcel",
    method: "get",
    responseType: "blob",
  });
};

ContactUsService.Activate = function (params) {
  return fetch({
    url: `/ContactUs/Activate`,
    method: "post",
    params,
  });
};

export default ContactUsService;
