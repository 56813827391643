import fetch from "auth/FetchInterceptor";

const bannerService = {};

bannerService.Create = function (data) {
  return fetch({
    url: "/Banner/Create",
    method: "post",
    data,
  });
};

bannerService.Update = function (data) {
  return fetch({
    url: "/Banner/Update",
    method: "post",
    data,
  });
};
bannerService.Activate = function (params) {
  return fetch({
    url: "/Banner/Activate",
    method: "post",
    params,
  });
};
bannerService.GetAll = function () {
  return fetch({
    url: "/Banner/GetAll",
    method: "get",
  });
};
bannerService.GetById = function (params) {
  return fetch({
    url: "/Banner/GetById",
    method: "get",
    params,
  });
};
bannerService.GetAllPages = function () {
  return fetch({
    url: "/Banner/GetAllPages",
    method: "get",
  });
};

export default bannerService;
