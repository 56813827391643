import fetch from "auth/FetchInterceptor";

const specialistService = {};

specialistService.Create = function (data) {
  return fetch({
    url: "/Specialist/Create",
    method: "post",
    data,
  });
};

specialistService.Update = function (data) {
  return fetch({
    url: "/Specialist/Update",
    method: "post",
    data,
  });
};
specialistService.Activate = function (params) {
  return fetch({
    url: "/Specialist/Activate",
    method: "post",
    params,
  });
};
specialistService.GetAll = function () {
  return fetch({
    url: "/Specialist/GetAll",
    method: "get",
  });
};
specialistService.GetById = function (params) {
  return fetch({
    url: "/Specialist/GetById",
    method: "get",
    params,
  });
};

export default specialistService;
