import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import newsAndEventsService from "services/NewsAndEventsService";
import { message } from "antd";

export const initialState = {
  loadingNewsAndEvents: false,
  loadingNewsAndEventsTypes: false,
  loadingActivateNewsAndEvents: false,
  allNewsAndEvents: [],
  allNewsAndEventsTypes: [],
  createLoading: false,
  dataSSR: [],
  totalRecord: null,
  loadingDataSSR: false,
  updateLoading: false,
  loadingNewsAndEventsbyId: false,
  newsAndEventsbyId: {},
  allTypes: [],
  loadingAllTypes: [],
};
export const GetSSR = createAsyncThunk(
  "/NewsAndEvent/GetAllSSR",
  async (data, { rejectWithValue }) => {
    try {
      const response = await newsAndEventsService.GetSSR(data);
      if (response.success === false) {
        return rejectWithValue(response.message);
      } else {
        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Create = createAsyncThunk(
  "/NewsAndEvent/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await newsAndEventsService.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/NewsAndEvent/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await newsAndEventsService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/NewsAndEvent/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await newsAndEventsService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/NewsAndEvent/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await newsAndEventsService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const GetNewsAndEventTypes = createAsyncThunk(
  "/NewsAndEvent/GetTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await newsAndEventsService.GetNewsAndEventTypes();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/NewsAndEvent/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await newsAndEventsService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const newAndEventsSlice = createSlice({
  name: "newAndEventsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetSSR.pending, (state) => {
        state.loadingDataSSR = true;
      })
      .addCase(GetSSR.fulfilled, (state, action) => {
        state.loadingDataSSR = false;
        state.dataSSR = action.payload.data;
        state.totalRecord = action.payload.totalRecord;
      })
      .addCase(GetSSR.rejected, (state, action) => {
        state.dataSSR = [];
        state.loadingDataSSR = false;
      })
      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingNewsAndEvents = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingNewsAndEvents = false;
        state.allNewsAndEvents = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingNewsAndEvents = false;
        state.allNewsAndEvents = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivateNewsAndEvents = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivateNewsAndEvents = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivateNewsAndEvents = false;
      })

      .addCase(GetNewsAndEventTypes.pending, (state) => {
        state.loadingNewsAndEventsTypes = true;
      })

      .addCase(GetNewsAndEventTypes.fulfilled, (state, action) => {
        state.loadingNewsAndEventsTypes = false;
        state.allNewsAndEventsTypes = action.payload;
      })
      .addCase(GetNewsAndEventTypes.rejected, (state, action) => {
        state.loadingNewsAndEventsTypes = false;
        state.allNewsAndEventsTypes = [];
      });
  },
});

export const {} = newAndEventsSlice.actions;

export default newAndEventsSlice.reducer;
