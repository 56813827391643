import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ourProcessService from "services/OurProcessService";
import { message } from "antd";

export const initialState = {
  loadingAllOurProcess: false,
  loadingActivateOurProcess: false,
  allOurProcess: [],
  createLoading: false,
  updateLoading: false,
  loadingOurProcessbyId: false,
  ourProcessbyId: {},
};

export const Create = createAsyncThunk(
  "/OurProcess/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ourProcessService.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/OurProcess/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await ourProcessService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/OurProcess/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ourProcessService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/OurProcess/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ourProcessService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/OurProcess/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ourProcessService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const ourProcessSlice = createSlice({
  name: "ourProcessSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingAllOurProcess = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllOurProcess = false;
        state.allOurProcess = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllOurProcess = false;
        state.allOurProcess = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivateOurProcess = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivateOurProcess = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivateOurProcess = false;
      });
  },
});

export const {} = ourProcessSlice.actions;

export default ourProcessSlice.reducer;
