import fetch from "auth/FetchInterceptor";

const FAQService = {};

FAQService.Create = function (data) {
  return fetch({
    url: "/FAQ/Create",
    method: "post",
    data,
  });
};

FAQService.Update = function (data) {
  return fetch({
    url: "/FAQ/Update",
    method: "post",
    data,
  });
};
FAQService.Activate = function (params) {
  return fetch({
    url: "/FAQ/Activate",
    method: "post",
    params,
  });
};
FAQService.GetAll = function () {
  return fetch({
    url: "/FAQ/GetAll",
    method: "get",
  });
};
FAQService.GetById = function (params) {
  return fetch({
    url: "/FAQ/GetById",
    method: "get",
    params,
  });
};

export default FAQService;
