import fetch from "auth/FetchInterceptor";

const howWeDoIt = {};

howWeDoIt.Update = function (data) {
  return fetch({
    url: "/HowWeDoIt/Update",
    method: "post",
    data,
  });
};

howWeDoIt.GetAll = function () {
  return fetch({
    url: "/HowWeDoIt/GetAll",
    method: "get",
  });
};

export default howWeDoIt;
