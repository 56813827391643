import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import partnerService from "services/PartnerService";
import { message } from "antd";

export const initialState = {
  loadingAllPartners: false,
  loadingActivatePartner: false,
  allPartners: [],
  createLoading: false,
  updateLoading: false,
  loadingPartnerById: false,
  partnerById: {},
};

export const Create = createAsyncThunk(
  "/Partner/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await partnerService.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/Partner/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await partnerService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/Partner/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await partnerService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/Partner/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await partnerService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/Partner/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await partnerService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const partnerSlice = createSlice({
  name: "partnerSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingAllPartners = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllPartners = false;
        state.allPartners = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllPartners = false;
        state.allPartners = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivatePartner = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivatePartner = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivatePartner = false;
      });
  },
});

export const {} = partnerSlice.actions;

export default partnerSlice.reducer;
