import fetch from "auth/FetchInterceptor";

const TeamMemberService = {};

TeamMemberService.Create = function (data) {
  return fetch({
    url: "/Team/Create",
    method: "post",
    data,
  });
};

TeamMemberService.Update = function (data) {
  return fetch({
    url: "/Team/Update",
    method: "post",
    data,
  });
};
TeamMemberService.Activate = function (params) {
  return fetch({
    url: "/Team/Activate",
    method: "post",
    params,
  });
};
TeamMemberService.GetAll = function () {
  return fetch({
    url: "/Team/GetAll",
    method: "get",
  });
};
TeamMemberService.GetSSR = async function (data) {
  return fetch({
    url: "/Team/GetAllSSR",
    method: "post",
    data: data,
  });
};
TeamMemberService.GetById = function (params) {
  return fetch({
    url: "/Team/GetById",
    method: "get",
    params,
  });
};

export default TeamMemberService;
