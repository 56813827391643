import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import howWeDoItService from "services/HowWeDoItService";
import { message } from "antd";

export const initialState = {
  loadingHowWeDoIt: false,
  allHowWeDoIt: [],
  updateLoading: false,
};

export const Update = createAsyncThunk(
  "/HowWeDoIt/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await howWeDoItService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const GetAll = createAsyncThunk(
  "/HowWeDoIt/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await howWeDoItService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const howWeDoItSlice = createSlice({
  name: "howWeDoItSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingHowWeDoIt = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingHowWeDoIt = false;
        state.allHowWeDoIt = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingHowWeDoIt = false;
        state.allHowWeDoIt = [];
      });
  },
});

export const {} = howWeDoItSlice.actions;

export default howWeDoItSlice.reducer;
