import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { message } from "antd";
import TestimonialService from "services/TestimonialService";

export const initialState = {
  loadingAllData: false,
  loadingActivate: false,
  allData: [],
  dataSSR: [],
  totalRecord: null,
  loadingDataSSR: false,
  createLoading: false,
  updateLoading: false,
  loadingDataById: false,
  dataById: {},
};

export const Create = createAsyncThunk(
  "/Testimonial/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TestimonialService.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/Testimonial/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await TestimonialService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetSSR = createAsyncThunk(
  "/Testimonial/GetAllSSR",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TestimonialService.GetSSR(data);
      if (response.success === false) {
        return rejectWithValue(response.message);
      } else {
        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/Testimonial/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await TestimonialService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAllTestimonials = createAsyncThunk(
  "/Testimonial/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await TestimonialService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/Testimonial/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TestimonialService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetSSR.pending, (state) => {
        state.loadingDataSSR = true;
      })
      .addCase(GetSSR.fulfilled, (state, action) => {
        state.loadingDataSSR = false;
        state.dataSSR = action.payload.data;
        state.totalRecord = action.payload.totalRecord;
      })
      .addCase(GetSSR.rejected, (state, action) => {
        state.dataSSR = [];
        state.loadingDataSSR = false;
      })

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAllTestimonials.pending, (state) => {
        state.loadingAllData = true;
      })

      .addCase(GetAllTestimonials.fulfilled, (state, action) => {
        state.loadingAllData = false;
        state.allData = action.payload;
      })
      .addCase(GetAllTestimonials.rejected, (state, action) => {
        state.loadingAllData = false;
        state.allData = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivate = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivate = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivate = false;
      });
  },
});

export const {} = testimonialSlice.actions;

export default testimonialSlice.reducer;
