import fetch from "auth/FetchInterceptor";

const addOnPackService = {};

addOnPackService.Create = function (data) {
  return fetch({
    url: "/AddOnPack/Create",
    method: "post",
    data,
  });
};

addOnPackService.Update = function (data) {
  return fetch({
    url: "/AddOnPack/Update",
    method: "post",
    data,
  });
};
addOnPackService.Activate = function (params) {
  return fetch({
    url: "/AddOnPack/Activate",
    method: "post",
    params,
  });
};
addOnPackService.GetAll = function () {
  return fetch({
    url: "/AddOnPack/GetAll",
    method: "get",
  });
};
addOnPackService.GetById = function (params) {
  return fetch({
    url: "/AddOnPack/GetById",
    method: "get",
    params,
  });
};

export default addOnPackService;
