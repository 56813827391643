import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import addOnPackService from "services/AddOnPackService";
import { message } from "antd";

export const initialState = {
  loadingAllAddOnPack: false,
  loadingActivateAddOnPack: false,
  allAddOnPack: [],
  createLoading: false,
  updateLoading: false,
  loadingAddOnPack: false,
  AddOnPackbyId: {},
};

export const Create = createAsyncThunk(
  "/AddOnPack/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addOnPackService.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/AddOnPack/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await addOnPackService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/AddOnPack/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await addOnPackService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/AddOnPack/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await addOnPackService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/AddOnPack/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addOnPackService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const addOnPackSlice = createSlice({
  name: "addOnPackSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingAllAddOnPack = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllAddOnPack = false;
        state.allAddOnPack = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllAddOnPack = false;
        state.allAddOnPack = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivateAddOnPack = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivateAddOnPack = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivateAddOnPack = false;
      });
  },
});

export const {} = addOnPackSlice.actions;

export default addOnPackSlice.reducer;
