import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import heroBannerService from "services/HeroBannerService";
import { message } from "antd";

export const initialState = {
  //   loadingAllBanners: false,
  loadingDeleteBanner: false,
  //   allBanners: [],
  createLoading: false,
  //   updateLoading: false,
  //   loadingBanerById: false,
  //   BannerById: {},
};

export const UploadFile = createAsyncThunk(
  "/Carrousel/UploadFiles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await heroBannerService.UploadFiles(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        /*    message.success({
          content: ` ${response.message}`,
        });
 */
        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/HeroBanner/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await heroBannerService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const DeleteSlider = createAsyncThunk(
  "/HeroBanner/Delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await heroBannerService.Delete(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const heroBannerSlice = createSlice({
  name: "heroBanner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(UploadFile.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(UploadFile.fulfilled, (state) => {
        state.createLoading = false;
      })
      .addCase(UploadFile.rejected, (state) => {
        state.createLoading = false;
      })

      .addCase(GetAll.pending, (state) => {
        state.loadingAllBanners = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllBanners = false;
        state.allBanners = action.payload;
      })
      .addCase(GetAll.rejected, (state) => {
        state.loadingAllBanners = false;
        state.allBanners = [];
      })
      .addCase(DeleteSlider.pending, (state) => {
        state.loadingDeleteBanner = true;
      })
      .addCase(DeleteSlider.fulfilled, (state) => {
        state.loadingDeleteBanner = false;
      })
      .addCase(DeleteSlider.rejected, (state) => {
        state.loadingDeleteBanner = false;
      });
  },
});

export const {} = heroBannerSlice.actions;

export default heroBannerSlice.reducer;
