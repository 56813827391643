import fetch from "auth/FetchInterceptor";

const serviceService = {};

serviceService.Create = function (data) {
  return fetch({
    url: "/Service/Create",
    method: "post",
    data,
  });
};

serviceService.Update = function (data) {
  return fetch({
    url: "/Service/Update",
    method: "post",
    data,
  });
};
serviceService.Activate = function (params) {
  return fetch({
    url: "/Service/Activate",
    method: "post",
    params,
  });
};
serviceService.GetAll = function () {
  return fetch({
    url: "/Service/GetAll",
    method: "get",
  });
};
serviceService.GetById = function (params) {
  return fetch({
    url: "/Service/GetById",
    method: "get",
    params,
  });
};

export default serviceService;
