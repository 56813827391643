import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import specialistService from "services/SpecialistService";
import { message } from "antd";

export const initialState = {
  loadingAllSpecialist: false,
  loadingActivateSpecialist: false,
  allSpecialist: [],
  createLoading: false,
  updateLoading: false,
  loadingSpecialistbyId: false,
  specialistbyId: {},
};

export const Create = createAsyncThunk(
  "/Specialist/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await specialistService.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/Specialist/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await specialistService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/Specialist/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await specialistService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/Specialist/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await specialistService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/Specialist/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await specialistService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const specialistSlice = createSlice({
  name: "specialistSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingAllSpecialist = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllSpecialist = false;
        state.allSpecialist = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllSpecialist = false;
        state.allSpecialist = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivateSpecialist = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivateSpecialist = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivateSpecialist = false;
      });
  },
});

export const {} = specialistSlice.actions;

export default specialistSlice.reducer;
