import fetch from "auth/FetchInterceptor";

const packageService = {};

packageService.Create = function (data) {
  return fetch({
    url: "/Package/Create",
    method: "post",
    data,
  });
};

packageService.Update = function (data) {
  return fetch({
    url: "/Package/Update",
    method: "post",
    data,
  });
};
packageService.Activate = function (params) {
  return fetch({
    url: "/Package/Activate",
    method: "post",
    params,
  });
};
packageService.GetAll = function () {
  return fetch({
    url: "/Package/GetAll",
    method: "get",
  });
};
packageService.GetById = function (params) {
  return fetch({
    url: "/Package/GetById",
    method: "get",
    params,
  });
};

export default packageService;
