import fetch from "auth/FetchInterceptor";

const aboutUsDetailsService = {};

aboutUsDetailsService.Create = function (data) {
  return fetch({
    url: "/AboutUsDetail/Create",
    method: "post",
    data,
  });
};

aboutUsDetailsService.Update = function (data) {
  return fetch({
    url: "/AboutUsDetail/Update",
    method: "post",
    data,
  });
};
aboutUsDetailsService.Activate = function (params) {
  return fetch({
    url: "/AboutUsDetail/Activate",
    method: "post",
    params,
  });
};
aboutUsDetailsService.GetAll = function () {
  return fetch({
    url: "/AboutUsDetail/GetAll",
    method: "get",
  });
};
aboutUsDetailsService.GetById = function (params) {
  return fetch({
    url: "/AboutUsDetail/GetById",
    method: "get",
    params,
  });
};

export default aboutUsDetailsService;
