import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ActivityService from "services/ActivityService";
import { message } from "antd";

export const initialState = {
  loadingAllData: false,
  loadingActivate: false,
  allData: [],
  dataSSR: [],
  totalRecord: null,
  loadingDataSSR: false,
  createLoading: false,
  loadingBanner: false,
  updateLoading: false,
  dataById: {},
  loadingGetById: false,
};
export const GetSSR = createAsyncThunk(
  "/Activity/GetAllSSR",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ActivityService.GetSSR(data);
      if (response.success === false) {
        return rejectWithValue(response.message);
      } else {
        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Create = createAsyncThunk(
  "/Activity/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ActivityService.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/Activity/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await ActivityService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/Activity/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ActivityService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/Activity/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ActivityService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/Activity/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ActivityService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UploadFile = createAsyncThunk(
  "/Activity/UploadFiles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ActivityService.UploadBanner(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        /*    message.success({
          content: ` ${response.message}`,
        });
 */
        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetSSR.pending, (state) => {
        state.loadingDataSSR = true;
      })
      .addCase(GetSSR.fulfilled, (state, action) => {
        state.loadingDataSSR = false;
        state.dataSSR = action.payload.data;
        state.totalRecord = action.payload.totalRecord;
      })
      .addCase(GetSSR.rejected, (state, action) => {
        state.dataSSR = [];
        state.loadingDataSSR = false;
      })

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetById.pending, (state) => {
        state.loadingGetById = true;
      })
      .addCase(GetById.fulfilled, (state, action) => {
        state.loadingGetById = false;
        state.dataById = action.payload;
      })
      .addCase(GetById.rejected, (state, action) => {
        state.loadingGetById = false;
        state.dataById = {};
      })

      .addCase(GetAll.pending, (state) => {
        state.loadingAllData = true;
      })
      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllData = false;
        state.allData = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllData = false;
        state.allData = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivate = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivate = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivate = false;
      })
      .addCase(UploadFile.pending, (state) => {
        state.loadingBanner = true;
      })
      .addCase(UploadFile.fulfilled, (state) => {
        state.loadingBanner = false;
      })
      .addCase(UploadFile.rejected, (state) => {
        state.loadingBanner = false;
      });
  },
});

export const {} = activitySlice.actions;

export default activitySlice.reducer;
