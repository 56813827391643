import fetch from "auth/FetchInterceptor";

const ResellerService = {};

ResellerService.GetAllSSR = async function (data) {
  return fetch({
    url: "/Reseller/GetAllSSR",
    method: "post",
    data,
  });
};
ResellerService.GetById = async function (params) {
  return fetch({
    url: "/Reseller/GetById",
    method: "get",
    params,
  });
};

ResellerService.Activate = function (params) {
  return fetch({
    url: `/Reseller/Activate`,
    method: "post",
    params,
  });
};

export default ResellerService;
