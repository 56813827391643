import fetch from "auth/FetchInterceptor";

const moduleService = {};

moduleService.Create = function (data) {
  return fetch({
    url: "/Module/Create",
    method: "post",
    data,
  });
};

moduleService.Update = function (data) {
  return fetch({
    url: "/Module/Update",
    method: "post",
    data,
  });
};
moduleService.Activate = function (params) {
  return fetch({
    url: "/Module/Activate",
    method: "post",
    params,
  });
};
moduleService.GetAll = function () {
  return fetch({
    url: "/Module/GetAll",
    method: "get",
  });
};
moduleService.GetById = function (params) {
  return fetch({
    url: "/Module/GetById",
    method: "get",
    params,
  });
};

export default moduleService;
