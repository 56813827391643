import fetch from "auth/FetchInterceptor";

const TestimonialService = {};

TestimonialService.Create = function (data) {
  return fetch({
    url: "/Testimonial/Create",
    method: "post",
    data,
  });
};

TestimonialService.Update = function (data) {
  return fetch({
    url: "/Testimonial/Update",
    method: "post",
    data,
  });
};
TestimonialService.Activate = function (params) {
  return fetch({
    url: "/Testimonial/Activate",
    method: "post",
    params,
  });
};
TestimonialService.GetAll = function () {
  return fetch({
    url: "/Testimonial/GetAll",
    method: "get",
  });
};
TestimonialService.GetSSR = async function (data) {
  return fetch({
    url: "/Testimonial/GetAllSSR",
    method: "post",
    data: data,
  });
};
TestimonialService.GetById = function (params) {
  return fetch({
    url: "/Testimonial/GetById",
    method: "get",
    params,
  });
};

export default TestimonialService;
