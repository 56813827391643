import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.login = async function (data) {
  return fetch({
    url: "/Account/Login",
    method: "post",
    data: data,
  });
};

AuthService.register = function (data) {
  return fetch({
    url: "/Account/Create",
    method: "post",
    data: data,
  });
};
AuthService.resetnewtokenpassword = async function (params) {
  return fetch({
    url: "/Account/resetnewtokenpassword",
    method: "post",
    params,
  });
};

AuthService.ResetPassword = function (data) {
  return fetch({
    url: "/Account/ResetPassword",
    method: "post",
    data,
  });
};

AuthService.logout = function () {
  return fetch({
    url: "/auth/logout",
    method: "post",
  });
};

AuthService.loginInOAuth = function () {
  return fetch({
    url: "/auth/loginInOAuth",
    method: "post",
  });
};

export default AuthService;
