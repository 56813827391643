import fetch from "auth/FetchInterceptor";

const blogService = {};

blogService.Create = function (data) {
  return fetch({
    url: "/Blog/Create",
    method: "post",
    data,
  });
};
blogService.GetSSR = async function (data) {
  return fetch({
    url: "/Blog/GetAllSSR",
    method: "post",
    data: data,
  });
};
blogService.Update = function (data) {
  return fetch({
    url: "/Blog/Update",
    method: "post",
    data,
  });
};
blogService.Activate = function (params) {
  return fetch({
    url: "/Blog/Activate",
    method: "post",
    params,
  });
};
blogService.GetAll = function () {
  return fetch({
    url: "/Blog/GetAll",
    method: "get",
  });
};
blogService.GetById = function (params) {
  return fetch({
    url: "/Blog/GetById",
    method: "get",
    params,
  });
};

export default blogService;
