import fetch from "auth/FetchInterceptor";

const addressDetailService = {};

addressDetailService.Create = function (data) {
  return fetch({
    url: "/AddressDetail/Create",
    method: "post",
    data,
  });
};

addressDetailService.Update = function (data) {
  return fetch({
    url: "/AddressDetail/Update",
    method: "post",
    data,
  });
};
addressDetailService.Activate = function (params) {
  return fetch({
    url: "/AddressDetail/Activate",
    method: "post",
    params,
  });
};
addressDetailService.GetAll = function () {
  return fetch({
    url: "/AddressDetail/GetAll",
    method: "get",
  });
};
addressDetailService.GetById = function (params) {
  return fetch({
    url: "/AddressDetail/GetById",
    method: "get",
    params,
  });
};

export default addressDetailService;
