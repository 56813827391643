import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { USER_INFO } from "constants/AuthConstant";
import AccountService from "services/AccountService";
import { message } from "antd";

export const initialState = {
  message: "",
  loadingUserProfile: false,
  loadingUpdateUserProfile: false,
  loadingUpdateChangePassword: false,
  userInfo: JSON.parse(localStorage.getItem(USER_INFO))?.role ?? "",
  ssrData: [],
  loadingSSR: false,
  loadingRoles: false,
  loadingCreate: false,
  roles: [],
  loadingIsActive: false,
  totalRecord: 0,
};

export const UserProfile = createAsyncThunk(
  "/Account/GetById",
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await AccountService.UserProfile({ userId });

      return response.result;
      //   }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAllSSR = createAsyncThunk(
  "/Account/GetAllSSR",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AccountService.GetAllSSR(data);

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Create = createAsyncThunk(
  "/Account/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AccountService.Create(data);
      if (response.success === false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const SetIsActive = createAsyncThunk(
  "/Account/SetIsActive",
  async ({ userId, status }, { rejectWithValue }) => {
    try {
      const response = await AccountService.SetIsActive({ userId, status });
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const UpdateProfile = createAsyncThunk(
  "/Account/Update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AccountService.UpdateProfile(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }

      return response.result;
      //   }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const ChangePassword = createAsyncThunk(
  "/Account/ChangePassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AccountService.ChangePassword(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetRoles = createAsyncThunk(
  "/Account/GetRoles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AccountService.GetRoles(data);
      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(UserProfile.pending, (state) => {
        state.loadingUserProfile = true;
      })
      .addCase(UserProfile.fulfilled, (state, action) => {
        state.loadingUserProfile = false;
      })
      .addCase(UserProfile.rejected, (state, action) => {
        state.loadingUserProfile = false;
      })

      .addCase(UpdateProfile.pending, (state) => {
        state.loadingUpdateUserProfile = true;
      })
      .addCase(UpdateProfile.fulfilled, (state, action) => {
        state.loadingUpdateUserProfile = false;
      })
      .addCase(UpdateProfile.rejected, (state, action) => {
        state.loadingUpdateUserProfile = false;
      })
      .addCase(ChangePassword.pending, (state) => {
        state.loadingUpdateChangePassword = true;
      })
      .addCase(ChangePassword.fulfilled, (state, action) => {
        state.loadingUpdateChangePassword = false;
      })
      .addCase(ChangePassword.rejected, (state, action) => {
        state.loadingUpdateChangePassword = false;
      })
      .addCase(Create.pending, (state) => {
        state.loadingCreate = true;
      })
      .addCase(Create.fulfilled, (state) => {
        state.loadingCreate = false;
      })
      .addCase(Create.rejected, (state) => {
        state.loadingCreate = false;
      })
      .addCase(GetRoles.pending, (state) => {
        state.loadingRoles = true;
      })
      .addCase(GetRoles.fulfilled, (state, action) => {
        state.loadingRoles = false;
        state.roles = action.payload;
      })
      .addCase(GetRoles.rejected, (state) => {
        state.loadingRoles = false;
        state.roles = [];
      })
      .addCase(SetIsActive.pending, (state) => {
        state.loadingIsActive = true;
      })
      .addCase(SetIsActive.fulfilled, (state) => {
        state.loadingIsActive = false;
      })
      .addCase(SetIsActive.rejected, (state) => {
        state.loadingIsActive = false;
      })
      .addCase(GetAllSSR.pending, (state) => {
        state.loadingSSR = true;
      })
      .addCase(GetAllSSR.fulfilled, (state, action) => {
        state.loadingSSR = false;
        state.ssrData = action?.payload?.data;
        state.totalRecord = action?.payload?.totalRecord;
      })
      .addCase(GetAllSSR.rejected, (state) => {
        state.loadingSSR = false;
        state.ssrData = [];
      });
  },
});

export const { setUserInfo } = accountSlice.actions;

export default accountSlice.reducer;
