import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ResellerService from "services/ResellerService";
import { message } from "antd";

export const initialState = {
  ssrData: [],
  loadingSSR: false,
  totalRecord: 0,
  loadingCreate: [],
  loadingUpdate: false,
  loadingActivate: false,
};

export const GetAllSSR = createAsyncThunk(
  "/Reseller/GetAllSSR",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ResellerService.GetAllSSR(data);

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const GetById = createAsyncThunk(
  "/Reseller/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ResellerService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/Reseller/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ResellerService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const resellerSlice = createSlice({
  name: "reseller",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllSSR.pending, (state) => {
        state.loadingSSR = true;
      })
      .addCase(GetAllSSR.fulfilled, (state, action) => {
        state.loadingSSR = false;
        state.ssrData = action.payload.data;
        state.totalRecord = action.payload.totalRecord;
      })
      .addCase(GetAllSSR.rejected, (state) => {
        state.loadingSSR = false;
        state.ssrData = [];
      })

      .addCase(Activate.pending, (state) => {
        state.loadingActivate = true;
      })
      .addCase(Activate.fulfilled, (state) => {
        state.loadingActivate = false;
      })
      .addCase(Activate.rejected, (state) => {
        state.loadingActivate = false;
      });
  },
});

export const {} = resellerSlice.actions;

export default resellerSlice.reducer;
