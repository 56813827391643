import fetch from "auth/FetchInterceptor";

const socialMediaServce = {};

socialMediaServce.Update = function (data) {
  return fetch({
    url: "/SocialMedia/Update",
    method: "post",
    data,
  });
};

socialMediaServce.GetAll = function () {
  return fetch({
    url: "/SocialMedia/GetAll",
    method: "get",
  });
};

export default socialMediaServce;
