import fetch from "auth/FetchInterceptor";

const howDoesItWorkService = {};

howDoesItWorkService.Create = function (data) {
  return fetch({
    url: "/HowDoesItWork/Create",
    method: "post",
    data,
  });
};

howDoesItWorkService.Update = function (data) {
  return fetch({
    url: "/HowDoesItWork/Update",
    method: "post",
    data,
  });
};
howDoesItWorkService.Activate = function (params) {
  return fetch({
    url: "/HowDoesItWork/Activate",
    method: "post",
    params,
  });
};
howDoesItWorkService.GetAll = function () {
  return fetch({
    url: "/HowDoesItWork/GetAll",
    method: "get",
  });
};
howDoesItWorkService.GetById = function (params) {
  return fetch({
    url: "/HowDoesItWork/GetById",
    method: "get",
    params,
  });
};

export default howDoesItWorkService;
