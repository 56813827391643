import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN, USER_INFO } from "constants/AuthConstant";
import AuthService from "services/AuthServicee";
import { message } from "antd";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  name: "",
  userId: "",
  role: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  resetNewTokenPasswordMessage: "",
  resetNewTokenPasswordLoading: false,
  resetPasswordMessage: "",
  loadingResetPassword: false,
};

export const signIn = createAsyncThunk(
  "/Account/Login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthService.login(data);
      if (response.success === false) {
        return rejectWithValue(response.message);
      } else {
        const token = response.result.accessToken;

        const { displayName, role, id, email } = response.result;

        const userInfo = {
          displayName,
          role,
          id,
          email,
        };
        localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
        localStorage.setItem(AUTH_TOKEN, token);
        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const signUp = createAsyncThunk(
  "Account/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthService.register(data);

      if (response.success === false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong!!!!"
      );
    }
  }
);
export const Resetnewtokenpassword = createAsyncThunk(
  "/Account/resetnewtokenpassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthService.resetnewtokenpassword(data);
      if (response.success === false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const ResetPassword = createAsyncThunk(
  "/Account/ResetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthService.ResetPassword(data);
      if (response.success === false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(USER_INFO);

  return true;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      //     state.redirect = "/";
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
    setIsCompletedProfile: (state, action) => {
      state.isCompletedProfile = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.role = action.payload.result.role;
        state.token = action.payload.result.accessToken;
        state.name = action.payload.result.displayName;
        state.userId = action.payload.result.id;
        state.isCompletedProfile = action.payload.result.isCompletedProfile;
        state.candidateId = action.payload.result.candidateId;
        state.agencyId = action.payload.result.agencyId;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.showMessage = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = false;
        state.loading = false;
      })

      .addCase(Resetnewtokenpassword.pending, (state) => {
        state.resetNewTokenPasswordLoading = true;
      })
      .addCase(Resetnewtokenpassword.fulfilled, (state, action) => {
        state.resetNewTokenPasswordMessage = action.message;
        state.resetNewTokenPasswordLoading = false;
      })
      .addCase(Resetnewtokenpassword.rejected, (state, action) => {
        state.resetNewTokenPasswordMessage = "";
        state.resetNewTokenPasswordLoading = false;
      })
      .addCase(ResetPassword.pending, (state) => {
        state.loadingResetPassword = true;
      })
      .addCase(ResetPassword.fulfilled, (state, action) => {
        state.resetPasswordMessage = action.message;
        state.loadingResetPassword = false;
      })
      .addCase(ResetPassword.rejected, (state, action) => {
        state.resetPasswordMessage = "";
        state.loadingResetPassword = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  setIsCompletedProfile,
} = authSlice.actions;

export default authSlice.reducer;
