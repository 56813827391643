import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import aboutUsDetails from "./slices/aboutUsDetailsSlice";
import addOnPack from "./slices/addOnPackSlice";
import addressDetail from "./slices/addressDetailSlice";
import banner from "./slices/bannerSlice";
import partner from "./slices/partnerSlice";
import blog from "./slices/blogSlice";
import faq from "./slices/faqSlice";
import howDoesItWork from "./slices/howDoesItWorkSlice";
import ourProcess from "./slices/ourProcessSlice";
import certificate from "./slices/certificateSlice";
import howWeDoIt from "./slices/howWeDoItSlice";
import packages from "./slices/packageSlice";
import module from "./slices/moduleSlice";
import specialist from "./slices/specialistSlice";
import newsAndEvents from "./slices/newAndEventsSlice";
import ourSolution from "./slices/ourSolutionSlice";
import service from "./slices/serviceSlice";
import rapidDeployment from "./slices/rapidDeploymentSlice";
import socialMedia from "./slices/socialMediaSlice";
import contactUs from "./slices/contactUsSlice";
import subscriber from "./slices/subscriberSlice";
import account from "./slices/accountSlice";
import reseller from "./slices/resellerSlice";
import activity from "./slices/activitySlice";
import teamMember from "./slices/teamMemberSlice";
import testimonial from "./slices/testimonialSlice";
import mailConfig from "./slices/mailConfig";
import heroBanner from "./slices/heroBannerSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    aboutUsDetails,
    banner,
    socialMedia,
    subscriber,
    teamMember,
    testimonial,
    certificate,
    account,
    mailConfig,
    heroBanner,
    service,
    contactUs,
    reseller,
    packages,
    ourSolution,
    blog,
    activity,
    partner,
    rapidDeployment,
    newsAndEvents,
    ourProcess,
    specialist,
    howDoesItWork,
    addOnPack,
    module,
    faq,
    addressDetail,
    howWeDoIt,
    auth,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
